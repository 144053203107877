@import "../../const/settings.scss";
.reputableDentistry {
  max-width: 1200px;
  // display: flex;
  margin: 40px auto;
  // flex-direction: column;
  // align-items: center;
  .wpTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
  }
  h2 {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 52px */
    text-transform: uppercase;
    color: #2a3467;
    margin-bottom: 8px;
    text-align: center;
  }
  .subTitle {
    max-width: 600px;
    color: var(--Color-4, #363636);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    // margin-bottom: 45px;
  }
  .line {
    height: 4px;
    width: 120px;
    border-radius: 3px;
    background-color: #00c5d7;
    margin-bottom: 12px;
  }
  .listDentistry {
    margin: 12px 0;
    width: 100%;
    .wpColClinic {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include media(sm) {
        &:nth-last-child(-n + 2) {
          display: none;
        }
      }
      @include media(ssm_360) {
        &:nth-last-child(-n + 2) {
          display: flex;
        }
        &:last-child {
          display: none;
        }
      }
      .skeletonTopClinic {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        height: 100%;
        width: fit-content !important;
        height: fit-content !important;
        border: 1px solid #e9e9e9 !important;
        // padding: 10px;
        > span {
          width: 60px !important;
          height: 60px !important;
          @include media(mdx) {
            width: 60px !important;
            height: 60px !important;
          }
          background-color: #fff;
          border-radius: 8px;
        }
      }
    }
  }
  .moreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #00c5d7;
    background-color: #fff;
    cursor: pointer;
    p {
      color: #00c5d7;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
  .wpButtonMore {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wpSkeletonItem {
  width: 100%;
  .ant-skeleton-element {
    width: 100%;
    .ant-skeleton-image {
      width: 100% !important;
    }
  }
}

@include media(lg) {
  .reputableDentistry {
    margin: 40px 15px;
  }
}
@include media(mdx) {
  .reputableDentistry {
    margin: 30px 15px;
    h2 {
      font-size: 20px !important;
    }
    .listDentistry {
      justify-content: center;
      display: flex;
      margin: 16px 0;
    }
    .moreButton {
      padding: 0 20px;
      height: 40px;
      p {
        font-size: 14px;
      }
    }
  }
}
