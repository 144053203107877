.wpSkeletonItem .skeletonImage {
  width: 100%;
  height: 320px;
  border-radius: 16px 16px 0 0;
}
.wpSkeletonItem .wpSkeletonInfo {
  padding: 24px;
  display: flex;
}
.wpSkeletonItem .wpSkeletonInfo .skeletonLogo {
  margin-right: 16px;
}
.wpSkeletonItem .wpSkeletonInfo .skeletonLogo .ant-skeleton-avatar-circle {
  height: 80px;
  width: 80px;
}
.wpSkeletonItem .wpSkeletonInfo .wpSkeletonDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wpSkeletonItem .wpSkeletonInfo .wpSkeletonDetails .skeletonInput1 {
  margin-bottom: 4px;
  width: 100%;
}
.wpSkeletonItem .wpSkeletonInfo .wpSkeletonDetails .skeletonInput2 {
  margin-bottom: 4px;
  width: 100%;
}
.wpSkeletonItem .wpSkeletonInfo .wpSkeletonDetails .wpSkeletonButton .skeletonButton1 {
  margin-right: 8px;
  width: 140px;
}
.wpSkeletonItem .wpSkeletonInfo .wpSkeletonDetails .wpSkeletonButton .skeletonButton2 {
  width: 140px;
}

.see-detail {
  height: 30px;
  width: 115px;
  border-radius: 30px;
  border: 1px solid var(--Color-2, #00c5d7) !important;
  background: var(--Color-3, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0;
}
.see-detail span {
  color: var(--Color-4, #363636);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
}